<template>
  <div>
    <v-app>
      <v-container>
        <v-row>
          <v-col lg="12" md="12" sm="12" xs="12">
            <v-tabs grow v-model="tab">
              <v-tab href="#merkezdetaylari"> Merkez detayları </v-tab>
              <v-tab href="#merkezarastirmacilari">
                Merkez Araştırmacıları
              </v-tab>
            </v-tabs>
            <v-tabs-items :value="tab">
              <v-tab-item value="merkezdetaylari">
                <v-container>
                  <v-form v-model="valid" ref="form">
                    <v-row>
                      <v-col md="6">
                        <div class="form-group">
                          <v-text-field
                            required
                            v-model="selected.centerName"
                            label="Merkez Adı (*)"
                            filled
                            dense
                            :rules="rules.required"
                          ></v-text-field>
                        </div>
                        <div class="form-group">
                          <v-autocomplete
                            auto-select-first
                            clearable
                            :items="specialtyDepartmentitems"
                            item-text="text"
                            item-value="value"
                            label="Bölüm Seçiniz"
                            v-model="selected.department"
                            filled
                            dense
                            required
                          ></v-autocomplete>
                        </div>
                        <div class="form-group">
                          <v-autocomplete
                            auto-select-first
                            clearable
                            :items="titleitems"
                            label=" Merkez Tipi Seçiniz"
                            v-model="selected.centerType"
                            filled
                            dense
                          ></v-autocomplete>
                        </div>
                        <div class="form-group">
                          <v-autocomplete
                            auto-select-first
                            clearable
                            :rules="rules.required"
                            label="Ülke Seçiniz (*)"
                            v-model="selected.country"
                            filled
                            dense
                            :items="countryitems"
                            item-text="countryName"
                            item-value="countryId"
                            @change="getCityByCountryId()"
                          ></v-autocomplete>
                        </div>
                        <div class="form-group">
                          <v-autocomplete
                            auto-select-first
                            clearable
                            label="İlçe Seçiniz (*)"
                            v-model="selected.district"
                            filled
                            dense
                            :items="districtitems"
                            item-text="townName"
                            item-value="townId"
                            :rules="rules.required"
                            no-data-text="Lütfen Önce İl Seçiniz"
                          ></v-autocomplete>
                        </div>
                      </v-col>
                      <v-col md="6">
                        <div class="form-group">
                          <v-text-field
                            required
                            v-model="selected.landPhone"
                            label="Sabit Telefon (*)"
                            filled
                            dense
                            :rules="rules.phone"
                            maxlength="10"
                          ></v-text-field>
                        </div>

                        <div class="form-group">
                          <v-text-field
                            required
                            v-model="selected.email"
                            label="E-posta"
                            filled
                            dense
                          ></v-text-field>
                        </div>

                        <div class="form-group">
                          <v-text-field
                            required
                            v-model="selected.webAddress"
                            label="Web adresi"
                            filled
                            dense
                          ></v-text-field>
                        </div>

                        <div class="form-group">
                          <v-autocomplete
                            required
                            auto-select-first
                            clearable
                            label="İl Seçiniz (*)"
                            v-model="selected.city"
                            filled
                            dense
                            :items="cityitems"
                            item-text="cityName"
                            item-value="cityId"
                            @change="getDistrictItems(selected.city)"
                            :rules="rules.required"
                            no-data-text="Lütfen Önce Ülke Seçiniz"
                          ></v-autocomplete>
                        </div>

                        <div class="form-group">
                          <v-text-field
                            required
                            v-model="selected.postCode"
                            label="Posta Kodu"
                            filled
                            dense
                            :rules="rules.postcode"
                            maxlength="5"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col>
                        <div class="form-group">
                          <v-row>
                            <v-col>
                              <v-textarea
                                required
                                v-model="selected.address"
                                label="Açık Adres"
                                filled
                                dense
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xl="2" lg="3" md="4 ml-auto">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="send"
                          :disabled="!valid"
                        >
                          Güncelle
                        </button>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-tab-item>
              <v-tab-item value="merkezarastirmacilari">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Araştırmacı Ara"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col lg="12" md="12" sm="12" xs="12">
                      <v-data-table
                        :headers="headers"
                        :items="centerResearchers"
                        :search="search"
                        :loading="loadings.table"
                        loading-text="Yükleniyor..."
                        :footer-props="{
                          'items-per-page-options': [20, 30, 40, -1],
                        }"
                        :items-per-page="20"
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "centersedit",
  data() {
    return {
      valid: true,
      rules: {
        required: [(v) => !!v || "Bu Alan Doldurulması Zorunludur."],
        counter: [
          (v) =>
            v.length >= 10 || "Telefon Numaranızı 10 Haneli Olarak Giriniz",
        ],
        phone: [
          (v) => {
            var regex = /[0-9]{10}/;
            return (
              (regex.test(v) && v.length >= 10) ||
              "Geçersiz Telefon Numarası.10 Haneli Olarak Giriniz"
            );
          },
        ],
        postcode: [
          (v) => {
            var regex = /[0-9]{5}/;
            return regex.test(v) || v.length == 0 || "Geçersiz Posta Kodu";
          },
        ],
        email: [
          (v) => {
            const pattern = /^([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            return pattern.test(v) || "Lütfen Geçerli E-posta Adresi Giriniz.";
          },
        ],
      },
      objeler: {
        id: "",
      },
      loadings: {
        table: false,
      },
      search: "",
      centerResearchers: [],
      headers: [
        {
          text: "Adı",
          align: "left",
          value: "name",
        },
        { text: "Soyadı", value: "surname" },
        { text: "Kullanıcı Adı", value: "username" },
        { text: "Ünvanı", value: "title" },
        { text: "Merkez/Organizasyon", value: "center.centerName" },
        { text: "Bölüm", value: "specialtyDepartment" },
        { text: "E-posta", value: "email" },
        { text: "Telefon", value: "phoneNumber" },
        { text: "Ülke", value: "country" },
        { text: "İl", value: "city" },
      ],
      currentid: null,
      selected: {
        centerName: "",
        department: "",
        centerType: "",
        landPhone: "",
        email: "",
        webAddress: "",
        country: "",
        city: "",
        district: "",
        postCode: "",
        address: "",
      },
      selectedCountryId: "",
      selectedCityId: "",
      titleitems: [
        { text: "Özel Hastane", value: "Özel Hastane" },
        {
          text: "Eğitim Araştırma Hastanesi",
          value: "Eğitim Araştırma Hastanesi",
        },
        { text: "Üniversite Hastanesi", value: "Üniversite Hastanesi" },
      ],
      countryitems: [],
      cityitems: [],
      districtitems: [],
      specialtyDepartmentitems: [
        { text: "Kardiyoloji", value: "Kardiyoloji" },
        { text: "Kardiyoloji ABD", value: "Kardiyoloji ABD" },
        { text: "Nefroloji", value: "Nefroloji" },
        { text: "Diğer", value: "Diğer" },
      ],
    };
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
  },
  mounted() {
    let selectedPage = this.$store.state.selectedpageitem.selectedPageItemName;
    if (selectedPage === "") {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Merkez Güncelleme", route: "/centersedit" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: selectedPage, route: "/centerlist" },
        { title: "Merkez Güncelleme" },
      ]);
    }

    var temp = localStorage.getItem("itemler");
    this.objeler = JSON.parse(temp);
    this.currentid = this.objeler.id;
    this.getCurrentCentersinfo();
  },
  methods: {
    send() {
      ApiService.put("/center", this.selected)
        .then(() => {
          // console.log("yeni merkezler güncellendi", data);
          Swal.fire({
            icon: "success",
            title: "Güncelleme işlemi başarılı",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          console.log("error", err);
        });
    },
    getCountryItems() {
      ApiService.get("countries")
        .then(({ data }) => {
          // console.log("data burada", data);
          this.countryitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCity(selectedCountryId) {
      if (selectedCountryId == null) {
        this.districtitems = [];
      }

      ApiService.get("cities", selectedCountryId)
        .then(({ data }) => {
          this.cityitems = data;
          
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCityByCountryId() {
      this.selected.city = "";
      this.selected.district = "";
      this.districtitems = [];
      
      if (this.selected.country == null) {
        this.cityitems = [];
      }
      this.getCity(this.selected.country);
    },
    getDistrictItems(selectedCityId) {
      if (selectedCityId == null) {
        this.districtitems = [];
      }
      ApiService.get("towns", selectedCityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getDistrictItemsByCityId(changeSelectedCity) {
      let changeSelectedCityId = this.cityitems.filter(
        (city) => city.cityName == changeSelectedCity
      );
      this.selected.district = "";
    
      ApiService.get("towns", changeSelectedCityId[0].cityId)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCurrentCentersinfo() {
      ApiService.get("/center", this.currentid)
        .then(({ data }) => {
          console.log("Merkez bilgileri geldi", data);
          this.getCountryItems();
          this.selected = data.result;
          this.centerResearchers = this.selected.users;
          this.selected.city = data.addResult[1].cityId;
          this.selected.country = data.addResult[0].countryId;
          this.selected.district = data.addResult[2].townId;

          this.getCity(this.selected.country);
          this.getDistrictItems(this.selected.city);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
